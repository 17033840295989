<!-- 商户余额交易记录 -->
<template>
  <a-card :bordered="false">
    <div class="summary">商户余额： {{ balanceAmount }}元</div>
    <div style="margin: 0px -24px 24px">
      <a-card
        style="width: 100%"
        :bordered="false"
        :tabList="historyTabs"
        :activeTabKey="tabTitleKey"
        @tabChange="(key) => (this.tabTitleKey = key)"
      ></a-card>
    </div>
    <div>
      <licenseeBalanceTransactionTableDisplay
        key="1"
        v-if="tabTitleKey === 'addValueHistory'"
        :columnHeader="addValueColumns"
        rowKey="billCode"
        :endpoint="transactionHistoryQueryEndpoint"
        :queryParam="addValueQueryParam"
      />
      <licenseeBalanceTransactionTableDisplay
        key="2"
        v-if="tabTitleKey === 'deductionHistory'"
        :columnHeader="deductionColumns"
        rowKey="billCode"
        :endpoint="transactionHistoryQueryEndpoint"
        :queryParam="deductionQueryParam"
      />
    </div>
  </a-card>
</template>

<script>
import licenseeBalanceTransactionTableDisplay from "./licenseeBalanceTransactionTableDisplay";

export default {
  name: "licenseeBalanceTransactionHistory",
  props: {
    accountId: String,
    balanceAmount: Number,
  },
  components: {
    licenseeBalanceTransactionTableDisplay,
  },
  data() {
    return {
      transactionHistoryQueryEndpoint: this.$endpoints.FINANCE_GET_LICENSEE_BALANCE_TRANSACTION_HISTORY,
      tabTitleKey: "addValueHistory",

      historyTabs: [
        {key: "addValueHistory", tab: "充值记录"},
        {key: "deductionHistory", tab: "扣费记录"}
      ],
      // 充值记录
      addValueColumns: [
        {
          title: "充值方式",
          width: 80,
          dataIndex: "method",
          key: "method",
          align: "center",
        },
        {
          title: "充值金额（元）",
          width: 80,
          dataIndex: "amount",
          key: "amount",
          align: "center",
        },
        {
          title: "充值时间",
          width: 100,
          dataIndex: "transactionDatetime",
          key: "transactionDatetime",
          align: "center",
        },
        {
          title: "账单编号",
          width: 80,
          dataIndex: "billCode",
          key: "billCode",
          align: "center",
        },
      ],

      addValueQueryParam: {
        accountId: this.accountId,
        transactionType: 4,
      },

      // 扣费记录
      deductionColumns: [
        {
          title: "扣费方式",
          width: 80,
          dataIndex: "method",
          key: "method",
          align: "center",
        },
        {
          title: "扣费金额（元）",
          width: 80,
          dataIndex: "amount",
          key: "amount",
          align: "center",
        },
        {
          title: "扣费时间",
          width: 100,
          dataIndex: "transactionDatetime",
          key: "transactionDatetime",
          align: "center",
        },
        {
          title: "账单编号",
          width: 80,
          dataIndex: "billCode",
          key: "billCode",
          align: "center",
        },
      ],

      deductionQueryParam: {
        accountId: this.accountId,
        transactionType: 5,
      },
    };
  },
};
</script>
<style lang="less">
.summary {
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: bold;
}
</style>