<!-- 查看商户余额详情 -->
<template>
 <div>
   <div style="margin-bottom: 24px">
    <licenseeInfo :contractId="contractId"/>
   </div>
   <div>
    <licenseeBalanceTransactionHistory
      :accountId="accountId"
      :balanceAmount="balanceAmount"
    />
   </div>
 </div>
</template>
<script>
import licenseeInfo from "./components/licenseeInfo"
import licenseeBalanceTransactionHistory from "./components/licenseeBalanceTransactionHistory"

export default {
  name: "licenseeBalanceDetail",
  components: {
    licenseeInfo,
    licenseeBalanceTransactionHistory,
  },
  props: {
    contractId: {
      type: Number,
      required: true,
    },
    accountId: {
      type: String,
      required: true,
    },
    balanceAmount: {
      type: Number,
      default: 0.0,
    }
  },
  data() {
    return {
    };
  },
  methods: {
    // 初始化
    init() {
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="less">
</style>
